.form-autocomplete {
  position: relative;
}

.form-autocomplete .form-autocomplete-input {
  height: auto;
  min-height: 1.6rem;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  padding: .1rem;
  display: flex;
}

.form-autocomplete .form-autocomplete-input.is-focused {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-autocomplete .form-autocomplete-input .form-input {
  box-shadow: none;
  height: 1.2rem;
  width: auto;
  border-color: #0000;
  flex: 1 0 auto;
  margin: .1rem;
  line-height: .8rem;
  display: inline-block;
}

.form-autocomplete .menu {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-autocomplete.autocomplete-oneline .form-autocomplete-input {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.form-autocomplete.autocomplete-oneline .chip {
  flex: 1 0 auto;
}

.calendar {
  min-width: 280px;
  border: .05rem solid #dadee4;
  border-radius: .1rem;
  display: block;
}

.calendar .calendar-nav {
  background: #f7f8f9;
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
  -ms-flex-align: center;
  align-items: center;
  padding: .4rem;
  font-size: .9rem;
  display: flex;
}

.calendar .calendar-body, .calendar .calendar-header {
  flex-wrap: wrap;
  justify-content: center;
  padding: .4rem 0;
  display: flex;
}

.calendar .calendar-body .calendar-date, .calendar .calendar-header .calendar-date {
  max-width: 14.28%;
  flex: 0 0 14.28%;
}

.calendar .calendar-header {
  color: #bcc3ce;
  text-align: center;
  background: #f7f8f9;
  border-bottom: .05rem solid #dadee4;
  font-size: .7rem;
}

.calendar .calendar-body {
  color: #66758c;
}

.calendar .calendar-date {
  border: 0;
  padding: .2rem;
}

.calendar .calendar-date .date-item {
  appearance: none;
  color: #66758c;
  cursor: pointer;
  height: 1.4rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 1.4rem;
  background: none;
  border: .05rem solid #0000;
  border-radius: 50%;
  outline: 0;
  padding: .1rem;
  font-size: .7rem;
  line-height: 1rem;
  text-decoration: none;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  position: relative;
}

.calendar .calendar-date .date-item.date-today {
  color: #5755d9;
  border-color: #e5e5f9;
}

.calendar .calendar-date .date-item:focus {
  box-shadow: 0 0 0 .1rem #5755d933;
}

.calendar .calendar-date .date-item:focus, .calendar .calendar-date .date-item:hover {
  color: #5755d9;
  background: #fefeff;
  border-color: #e5e5f9;
  text-decoration: none;
}

.calendar .calendar-date .date-item.active, .calendar .calendar-date .date-item:active {
  color: #fff;
  background: #4b48d6;
  border-color: #3634d2;
}

.calendar .calendar-date .date-item.badge:after {
  position: absolute;
  top: 3px;
  right: 3px;
  transform: translate(50%, -50%);
}

.calendar .calendar-date .calendar-event.disabled, .calendar .calendar-date .calendar-event:disabled, .calendar .calendar-date .date-item.disabled, .calendar .calendar-date .date-item:disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.calendar .calendar-date.next-month .calendar-event, .calendar .calendar-date.next-month .date-item, .calendar .calendar-date.prev-month .calendar-event, .calendar .calendar-date.prev-month .date-item {
  opacity: .25;
}

.calendar .calendar-range {
  position: relative;
}

.calendar .calendar-range:before {
  content: "";
  height: 1.4rem;
  background: #f1f1fc;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.calendar .calendar-range.range-start:before {
  left: 50%;
}

.calendar .calendar-range.range-end:before {
  right: 50%;
}

.calendar .calendar-range.range-end .date-item, .calendar .calendar-range.range-start .date-item {
  color: #fff;
  background: #4b48d6;
  border-color: #3634d2;
}

.calendar .calendar-range .date-item {
  color: #5755d9;
}

.calendar.calendar-lg .calendar-body {
  padding: 0;
}

.calendar.calendar-lg .calendar-body .calendar-date {
  height: 5.5rem;
  border-bottom: .05rem solid #dadee4;
  border-right: .05rem solid #dadee4;
  flex-direction: column;
  padding: 0;
  display: flex;
}

.calendar.calendar-lg .calendar-body .calendar-date:nth-child(7n) {
  border-right: 0;
}

.calendar.calendar-lg .calendar-body .calendar-date:nth-last-child(-n+7) {
  border-bottom: 0;
}

.calendar.calendar-lg .date-item {
  height: 1.4rem;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-top: .2rem;
  margin-right: .2rem;
}

.calendar.calendar-lg .calendar-range:before {
  top: 19px;
}

.calendar.calendar-lg .calendar-range.range-start:before {
  width: 19px;
  left: auto;
}

.calendar.calendar-lg .calendar-range.range-end:before {
  right: 19px;
}

.calendar.calendar-lg .calendar-events {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: .2rem;
  line-height: 1;
  overflow-y: auto;
}

.calendar.calendar-lg .calendar-event {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: .1rem;
  margin: .1rem auto;
  padding: 3px 4px;
  font-size: .7rem;
  display: block;
  overflow: hidden;
}

.carousel .carousel-locator:nth-of-type(1):checked ~ .carousel-container .carousel-item:nth-of-type(1), .carousel .carousel-locator:nth-of-type(2):checked ~ .carousel-container .carousel-item:nth-of-type(2), .carousel .carousel-locator:nth-of-type(3):checked ~ .carousel-container .carousel-item:nth-of-type(3), .carousel .carousel-locator:nth-of-type(4):checked ~ .carousel-container .carousel-item:nth-of-type(4), .carousel .carousel-locator:nth-of-type(5):checked ~ .carousel-container .carousel-item:nth-of-type(5), .carousel .carousel-locator:nth-of-type(6):checked ~ .carousel-container .carousel-item:nth-of-type(6), .carousel .carousel-locator:nth-of-type(7):checked ~ .carousel-container .carousel-item:nth-of-type(7), .carousel .carousel-locator:nth-of-type(8):checked ~ .carousel-container .carousel-item:nth-of-type(8) {
  opacity: 1;
  z-index: 100;
  animation: .75s ease-in-out carousel-slidein;
}

.carousel .carousel-locator:nth-of-type(1):checked ~ .carousel-nav .nav-item:nth-of-type(1), .carousel .carousel-locator:nth-of-type(2):checked ~ .carousel-nav .nav-item:nth-of-type(2), .carousel .carousel-locator:nth-of-type(3):checked ~ .carousel-nav .nav-item:nth-of-type(3), .carousel .carousel-locator:nth-of-type(4):checked ~ .carousel-nav .nav-item:nth-of-type(4), .carousel .carousel-locator:nth-of-type(5):checked ~ .carousel-nav .nav-item:nth-of-type(5), .carousel .carousel-locator:nth-of-type(6):checked ~ .carousel-nav .nav-item:nth-of-type(6), .carousel .carousel-locator:nth-of-type(7):checked ~ .carousel-nav .nav-item:nth-of-type(7), .carousel .carousel-locator:nth-of-type(8):checked ~ .carousel-nav .nav-item:nth-of-type(8) {
  color: #f7f8f9;
}

.carousel {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  z-index: 1;
  background: #f7f8f9;
  display: block;
  position: relative;
  overflow: hidden;
}

.carousel .carousel-container {
  height: 100%;
  position: relative;
  left: 0;
}

.carousel .carousel-container:before {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.carousel .carousel-container .carousel-item {
  height: 100%;
  opacity: 0;
  width: 100%;
  margin: 0;
  animation: 1s ease-in-out carousel-slideout;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel .carousel-container .carousel-item:hover .item-next, .carousel .carousel-container .carousel-item:hover .item-prev {
  opacity: 1;
}

.carousel .carousel-container .item-next, .carousel .carousel-container .item-prev {
  color: #f7f8f9;
  opacity: 0;
  z-index: 100;
  background: #f7f8f940;
  border-color: #f7f8f980;
  transition: all .4s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .carousel-container .item-prev {
  left: 1rem;
}

.carousel .carousel-container .item-next {
  right: 1rem;
}

.carousel .carousel-nav {
  width: 10rem;
  z-index: 100;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

.carousel .carousel-nav .nav-item {
  color: #f7f8f980;
  height: 1.6rem;
  max-width: 2.5rem;
  flex: 1 0 auto;
  margin: .2rem;
  display: block;
  position: relative;
}

.carousel .carousel-nav .nav-item:before {
  content: "";
  height: .1rem;
  width: 100%;
  background: currentColor;
  display: block;
  position: absolute;
  top: .5rem;
}

@keyframes carousel-slidein {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes carousel-slideout {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}

.comparison-slider {
  height: 50vh;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.comparison-slider .comparison-after, .comparison-slider .comparison-before {
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.comparison-slider .comparison-after img, .comparison-slider .comparison-before img {
  height: 100%;
  object-fit: cover;
  object-position: left center;
  width: 100%;
  position: absolute;
}

.comparison-slider .comparison-before {
  width: 100%;
  z-index: 1;
}

.comparison-slider .comparison-before .comparison-label {
  right: .8rem;
}

.comparison-slider .comparison-after {
  max-width: 100%;
  min-width: 0;
  z-index: 2;
}

.comparison-slider .comparison-after:before {
  content: "";
  cursor: default;
  height: 100%;
  z-index: 1;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  right: .8rem;
}

.comparison-slider .comparison-after:after {
  color: #fff;
  content: "";
  height: 3px;
  pointer-events: none;
  width: 3px;
  background: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: .4rem;
  transform: translate(50%, -50%);
  box-shadow: 0 -5px, 0 5px;
}

.comparison-slider .comparison-after .comparison-label {
  left: .8rem;
}

.comparison-slider .comparison-resizer {
  cursor: ew-resize;
  height: .8rem;
  max-width: 100%;
  min-width: .8rem;
  opacity: 0;
  resize: horizontal;
  width: 0;
  outline: 0;
  animation: 1.5s ease-in-out first-run;
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-50%)scaleY(30);
}

.comparison-slider .comparison-label {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background: #30374280;
  padding: .2rem .4rem;
  position: absolute;
  bottom: .8rem;
}

@keyframes first-run {
  0% {
    width: 0;
  }

  25% {
    width: 2.4rem;
  }

  50% {
    width: .8rem;
  }

  75% {
    width: 1.2rem;
  }

  100% {
    width: 0;
  }
}

.filter .filter-tag#tag-0:checked ~ .filter-nav .chip[for="tag-0"], .filter .filter-tag#tag-1:checked ~ .filter-nav .chip[for="tag-1"], .filter .filter-tag#tag-2:checked ~ .filter-nav .chip[for="tag-2"], .filter .filter-tag#tag-3:checked ~ .filter-nav .chip[for="tag-3"], .filter .filter-tag#tag-4:checked ~ .filter-nav .chip[for="tag-4"], .filter .filter-tag#tag-5:checked ~ .filter-nav .chip[for="tag-5"], .filter .filter-tag#tag-6:checked ~ .filter-nav .chip[for="tag-6"], .filter .filter-tag#tag-7:checked ~ .filter-nav .chip[for="tag-7"], .filter .filter-tag#tag-8:checked ~ .filter-nav .chip[for="tag-8"] {
  color: #fff;
  background: #5755d9;
}

.filter .filter-tag#tag-1:checked ~ .filter-body .filter-item:not([data-tag~="tag-1"]), .filter .filter-tag#tag-2:checked ~ .filter-body .filter-item:not([data-tag~="tag-2"]), .filter .filter-tag#tag-3:checked ~ .filter-body .filter-item:not([data-tag~="tag-3"]), .filter .filter-tag#tag-4:checked ~ .filter-body .filter-item:not([data-tag~="tag-4"]), .filter .filter-tag#tag-5:checked ~ .filter-body .filter-item:not([data-tag~="tag-5"]), .filter .filter-tag#tag-6:checked ~ .filter-body .filter-item:not([data-tag~="tag-6"]), .filter .filter-tag#tag-7:checked ~ .filter-body .filter-item:not([data-tag~="tag-7"]), .filter .filter-tag#tag-8:checked ~ .filter-body .filter-item:not([data-tag~="tag-8"]) {
  display: none;
}

.filter .filter-nav {
  margin: .4rem 0;
}

.filter .filter-body {
  flex-wrap: wrap;
  display: flex;
}

.meter {
  appearance: none;
  height: .8rem;
  width: 100%;
  background: #f7f8f9;
  border: 0;
  border-radius: .1rem;
  display: block;
}

.meter::-webkit-meter-inner-element {
  display: block;
}

.meter::-webkit-meter-bar, .meter::-webkit-meter-even-less-good-value, .meter::-webkit-meter-optimum-value, .meter::-webkit-meter-suboptimum-value {
  border-radius: .1rem;
}

.meter::-webkit-meter-bar {
  background: #f7f8f9;
}

.meter::-webkit-meter-optimum-value {
  background: #32b643;
}

.meter::-webkit-meter-suboptimum-value {
  background: #ffb700;
}

.meter::-webkit-meter-even-less-good-value {
  background: #e85600;
}

.meter:-moz-meter-optimum, .meter:-moz-meter-sub-optimum, .meter:-moz-meter-sub-sub-optimum, .meter::-moz-meter-bar {
  border-radius: .1rem;
}

.meter:-moz-meter-optimum::-moz-meter-bar {
  background: #32b643;
}

.meter:-moz-meter-sub-optimum::-moz-meter-bar {
  background: #ffb700;
}

.meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
  background: #e85600;
}

.off-canvas {
  height: 100%;
  width: 100%;
  flex-flow: row;
  display: flex;
  position: relative;
}

.off-canvas .off-canvas-toggle {
  z-index: 1;
  transition: none;
  display: block;
  position: absolute;
  top: .4rem;
  left: .4rem;
}

.off-canvas .off-canvas-sidebar {
  min-width: 10rem;
  z-index: 200;
  background: #f7f8f9;
  transition: transform .25s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  transform: translateX(-100%);
}

.off-canvas .off-canvas-content {
  height: 100%;
  flex: auto;
  padding: .4rem .4rem .4rem 4rem;
}

.off-canvas .off-canvas-overlay {
  height: 100%;
  width: 100%;
  background: #3037421a;
  border-color: #0000;
  border-radius: 0;
  display: none;
  position: fixed;
  inset: 0;
}

.off-canvas .off-canvas-sidebar.active, .off-canvas .off-canvas-sidebar:target {
  transform: translateX(0);
}

.off-canvas .off-canvas-sidebar.active ~ .off-canvas-overlay, .off-canvas .off-canvas-sidebar:target ~ .off-canvas-overlay {
  z-index: 100;
  display: block;
}

@media (min-width: 960px) {
  .off-canvas.off-canvas-sidebar-show .off-canvas-toggle {
    display: none;
  }

  .off-canvas.off-canvas-sidebar-show .off-canvas-sidebar {
    flex: none;
    position: relative;
    transform: none;
  }

  .off-canvas.off-canvas-sidebar-show .off-canvas-overlay {
    display: none !important;
  }
}

.parallax {
  height: auto;
  width: auto;
  display: block;
  position: relative;
}

.parallax .parallax-content {
  height: auto;
  transform-style: preserve-3d;
  width: 100%;
  transition: all .4s;
  transform: perspective(1000px);
  box-shadow: 0 1rem 2.1rem #3037424d;
}

.parallax .parallax-content:before {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.parallax .parallax-front {
  color: #fff;
  height: 100%;
  text-align: center;
  text-shadow: 0 0 20px #303742bf;
  width: 100%;
  z-index: 1;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  transition: transform .4s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(50px)scale(.95);
}

.parallax .parallax-top-left {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.parallax .parallax-top-left:focus ~ .parallax-content, .parallax .parallax-top-left:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(3deg)rotateY(-3deg);
}

.parallax .parallax-top-left:focus ~ .parallax-content:before, .parallax .parallax-top-left:hover ~ .parallax-content:before {
  background: linear-gradient(135deg, #ffffff59 0, #0000 50%);
}

.parallax .parallax-top-left:focus ~ .parallax-content .parallax-front, .parallax .parallax-top-left:hover ~ .parallax-content .parallax-front {
  transform: translate3d(4.5px, 4.5px, 50px)scale(.95);
}

.parallax .parallax-top-right {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.parallax .parallax-top-right:focus ~ .parallax-content, .parallax .parallax-top-right:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(3deg)rotateY(3deg);
}

.parallax .parallax-top-right:focus ~ .parallax-content:before, .parallax .parallax-top-right:hover ~ .parallax-content:before {
  background: linear-gradient(-135deg, #ffffff59 0, #0000 50%);
}

.parallax .parallax-top-right:focus ~ .parallax-content .parallax-front, .parallax .parallax-top-right:hover ~ .parallax-content .parallax-front {
  transform: translate3d(-4.5px, 4.5px, 50px)scale(.95);
}

.parallax .parallax-bottom-left {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.parallax .parallax-bottom-left:focus ~ .parallax-content, .parallax .parallax-bottom-left:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(-3deg)rotateY(-3deg);
}

.parallax .parallax-bottom-left:focus ~ .parallax-content:before, .parallax .parallax-bottom-left:hover ~ .parallax-content:before {
  background: linear-gradient(45deg, #ffffff59 0, #0000 50%);
}

.parallax .parallax-bottom-left:focus ~ .parallax-content .parallax-front, .parallax .parallax-bottom-left:hover ~ .parallax-content .parallax-front {
  transform: translate3d(4.5px, -4.5px, 50px)scale(.95);
}

.parallax .parallax-bottom-right {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.parallax .parallax-bottom-right:focus ~ .parallax-content, .parallax .parallax-bottom-right:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(-3deg)rotateY(3deg);
}

.parallax .parallax-bottom-right:focus ~ .parallax-content:before, .parallax .parallax-bottom-right:hover ~ .parallax-content:before {
  background: linear-gradient(-45deg, #ffffff59 0, #0000 50%);
}

.parallax .parallax-bottom-right:focus ~ .parallax-content .parallax-front, .parallax .parallax-bottom-right:hover ~ .parallax-content .parallax-front {
  transform: translate3d(-4.5px, -4.5px, 50px)scale(.95);
}

.progress {
  appearance: none;
  color: #5755d9;
  height: .2rem;
  width: 100%;
  background: #eef0f3;
  border: 0;
  border-radius: .1rem;
  position: relative;
}

.progress::-webkit-progress-bar {
  background: none;
  border-radius: .1rem;
}

.progress::-webkit-progress-value {
  background: #5755d9;
  border-radius: .1rem;
}

.progress::-moz-progress-bar {
  background: #5755d9;
  border-radius: .1rem;
}

.progress:indeterminate {
  background: #eef0f3 linear-gradient(to right, #5755d9 30%, #eef0f3 30%) 0 0 / 150% 150% no-repeat;
  animation: 1.5s linear infinite progress-indeterminate;
}

.progress:indeterminate::-moz-progress-bar {
  background: none;
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.slider {
  appearance: none;
  height: 1.2rem;
  width: 100%;
  background: none;
  display: block;
}

.slider:focus {
  outline: 0;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.slider.tooltip:not([data-tooltip]):after {
  content: attr(value);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: .6rem;
  width: .6rem;
  background: #5755d9;
  border: 0;
  border-radius: 50%;
  margin-top: -.25rem;
  transition: transform .2s;
}

.slider::-moz-range-thumb {
  height: .6rem;
  width: .6rem;
  background: #5755d9;
  border: 0;
  border-radius: 50%;
  transition: transform .2s;
}

.slider::-ms-thumb {
  height: .6rem;
  width: .6rem;
  background: #5755d9;
  border: 0;
  border-radius: 50%;
  transition: transform .2s;
}

.slider:active::-webkit-slider-thumb {
  transform: scale(1.25);
}

.slider:active::-moz-range-thumb {
  transform: scale(1.25);
}

.slider:active::-ms-thumb {
  transform: scale(1.25);
}

.slider.disabled::-webkit-slider-thumb, .slider:disabled::-webkit-slider-thumb {
  background: #f7f8f9;
  transform: scale(1);
}

.slider.disabled::-moz-range-thumb, .slider:disabled::-moz-range-thumb {
  background: #f7f8f9;
  transform: scale(1);
}

.slider.disabled::-ms-thumb, .slider:disabled::-ms-thumb {
  background: #f7f8f9;
  transform: scale(1);
}

.slider::-webkit-slider-runnable-track {
  height: .1rem;
  width: 100%;
  background: #eef0f3;
  border-radius: .1rem;
}

.slider::-moz-range-track {
  height: .1rem;
  width: 100%;
  background: #eef0f3;
  border-radius: .1rem;
}

.slider::-ms-track {
  height: .1rem;
  width: 100%;
  background: #eef0f3;
  border-radius: .1rem;
}

.slider::-ms-fill-lower {
  background: #5755d9;
}

.timeline .timeline-item {
  margin-bottom: 1.2rem;
  display: flex;
  position: relative;
}

.timeline .timeline-item:before {
  content: "";
  height: 100%;
  width: 2px;
  background: #dadee4;
  position: absolute;
  top: 1.2rem;
  left: 11px;
}

.timeline .timeline-item .timeline-left {
  flex: none;
}

.timeline .timeline-item .timeline-content {
  flex: auto;
  padding: 2px 0 2px .8rem;
}

.timeline .timeline-item .timeline-icon {
  color: #fff;
  height: 1.2rem;
  text-align: center;
  width: 1.2rem;
  border-radius: 50%;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timeline .timeline-item .timeline-icon:before {
  content: "";
  height: .4rem;
  width: .4rem;
  border: .1rem solid #5755d9;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: .4rem;
  left: .4rem;
}

.timeline .timeline-item .timeline-icon.icon-lg {
  background: #5755d9;
  line-height: 1.2rem;
}

.timeline .timeline-item .timeline-icon.icon-lg:before {
  content: none;
}

.viewer-360 {
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.viewer-360 .viewer-slider[max="36"][value="1"] + .viewer-image {
  background-position-y: 0;
}

.viewer-360 .viewer-slider[max="36"][value="2"] + .viewer-image {
  background-position-y: 2.85714%;
}

.viewer-360 .viewer-slider[max="36"][value="3"] + .viewer-image {
  background-position-y: 5.71429%;
}

.viewer-360 .viewer-slider[max="36"][value="4"] + .viewer-image {
  background-position-y: 8.57143%;
}

.viewer-360 .viewer-slider[max="36"][value="5"] + .viewer-image {
  background-position-y: 11.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="6"] + .viewer-image {
  background-position-y: 14.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="7"] + .viewer-image {
  background-position-y: 17.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="8"] + .viewer-image {
  background-position-y: 20%;
}

.viewer-360 .viewer-slider[max="36"][value="9"] + .viewer-image {
  background-position-y: 22.8571%;
}

.viewer-360 .viewer-slider[max="36"][value="10"] + .viewer-image {
  background-position-y: 25.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="11"] + .viewer-image {
  background-position-y: 28.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="12"] + .viewer-image {
  background-position-y: 31.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="13"] + .viewer-image {
  background-position-y: 34.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="14"] + .viewer-image {
  background-position-y: 37.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="15"] + .viewer-image {
  background-position-y: 40%;
}

.viewer-360 .viewer-slider[max="36"][value="16"] + .viewer-image {
  background-position-y: 42.8571%;
}

.viewer-360 .viewer-slider[max="36"][value="17"] + .viewer-image {
  background-position-y: 45.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="18"] + .viewer-image {
  background-position-y: 48.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="19"] + .viewer-image {
  background-position-y: 51.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="20"] + .viewer-image {
  background-position-y: 54.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="21"] + .viewer-image {
  background-position-y: 57.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="22"] + .viewer-image {
  background-position-y: 60%;
}

.viewer-360 .viewer-slider[max="36"][value="23"] + .viewer-image {
  background-position-y: 62.8571%;
}

.viewer-360 .viewer-slider[max="36"][value="24"] + .viewer-image {
  background-position-y: 65.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="25"] + .viewer-image {
  background-position-y: 68.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="26"] + .viewer-image {
  background-position-y: 71.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="27"] + .viewer-image {
  background-position-y: 74.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="28"] + .viewer-image {
  background-position-y: 77.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="29"] + .viewer-image {
  background-position-y: 80%;
}

.viewer-360 .viewer-slider[max="36"][value="30"] + .viewer-image {
  background-position-y: 82.8572%;
}

.viewer-360 .viewer-slider[max="36"][value="31"] + .viewer-image {
  background-position-y: 85.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="32"] + .viewer-image {
  background-position-y: 88.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="33"] + .viewer-image {
  background-position-y: 91.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="34"] + .viewer-image {
  background-position-y: 94.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="35"] + .viewer-image {
  background-position-y: 97.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="36"] + .viewer-image {
  background-position-y: 100%;
}

.viewer-360 .viewer-slider {
  cursor: ew-resize;
  width: 60%;
  order: 2;
  margin: 1rem;
}

.viewer-360 .viewer-image {
  max-width: 100%;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  order: 1;
}

/*# sourceMappingURL=index.7ccb1cf7.css.map */
